<template>
  <el-dialog
      :title="$t('SetMembershipFee')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="900px"
      class="manage-group"
  >
    <!-- 表格区域 -->
    <div class="table-box">
      <table>
        <tr
            v-for="(item, index) in groupList"
            :key="item.id"
            class="table-group"
        >
          <!--组名-->
          <td class="group-td">
            <div class="td-flex">
              <span class="group-name">{{ item.groupName ? item.groupName : '-' }}</span>
            </div>
          </td>
          <!--备注-->
          <td class="group-td">
            <div class="td-flex">
              <span class="group-node">{{ item.node ? item.node : '-' }}</span>
            </div>
          </td>
          <!--人数-->
          <td class="number-td">
            <div class="td-flex">
              <span>{{ $t('PeopleNumber') }}</span>
              <span>{{ item.groupNumber }}</span>
            </div>
          </td>
          <!--会费-->
          <td class="oper-td">
            <div class="td-flex">
              <span
                v-if="item.isRemarkShow"
                class="group-node"
                style="color: #C50707"
            >{{ item.price+'&nbsp' + item.priceType +'/'+ $t('Year')}}</span>
              <el-input
                  v-if="!item.isRemarkShow"
                  v-model="pendingPrice"
                  :placeholder="$t('RemarkPlaceholder')"
                  maxlength="100"
                  @blur="blurFee(item)"
              ></el-input>
              <u
                  v-show="item.isRemarkShow"
                  @click="groupFeeEdit(item)"
              >
                <i class="el-icon-edit"></i>
                {{ $t('Edit') }}
              </u>
            </div>
            
          </td>
        </tr>
      </table>
    </div>
    
    <!--缴费时段-->
    <div class="payTime">
      <span>{{$t('PayTime')}}</span>
      <el-date-picker
          v-model="value1"
          type="daterange"
          value-format="yyyy-MM-dd"
          @change="ifOneMonth"
          :picker-options="pickerOptions"
          :start-placeholder="$t('StartTime')"
          :end-placeholder="$t('EndTme')">
      </el-date-picker>
    </div>
    
    <span slot="footer">
      <el-button @click="cancelFn">{{ $t('Cancle') }}</el-button>
      <el-button
          type="primary"
          :loading="loading"
          @click="submitData"
      >{{ $t('Confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deepCopy } from "@/utils/tool.js";
import api from "@/fetch/api";
import moment from "moment";

export default {
  name: "SetMembership",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set() {
        this.cancelFn();
      },
    },
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      chamberId: data.chamberId,
      adminId: data.id,
      notGroupItem: {}, // 未分组
      groupList: [], // 分组数据
      // 源对象的下标
      dragIndex: "",
      // 目标对象的下标
      enterIndex: "",
      timeout: null,
      pendingPrice:0,
      groupIds:[],
      priceList:[],
      value1:'',
      objList:[],
      loading:false,
      
      
      // 新建组
      showAddGroupBtn: true, // 是否显示新建组按钮
      addGroupId: "", // 自定义新增对象id
      operateType: "add", // 操作类型
  
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
    };
  },
  created() {
    this.getGroupMemberList();
  },
  methods: {
    // 获取分组成员列表
    getGroupMemberList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: 1,
        pageSize: 7,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      api.getGroupByChamberId(params).then((res) => {
        const copyData = deepCopy(res.data.data.records);
        const filterArr = [];
        if (copyData.length) {
          copyData.forEach((item) => {
            item.isNameShow = true;
            item.isRemarkShow = true;
            item.changed = false;
            this.groupIds.push(item.groupId)
            this.priceList.push(item.price)
            filterArr.push(item)
          });
        }
        this.groupList = filterArr;
      });
    },
    
    // 取消
    cancelFn() {
      // 每次关闭对话框时，清除定时器
      clearTimeout(this.timeout);
      this.timeout = null;
      
      this.$emit("update:show", false);
    },
    
    // 费用编辑
    groupFeeEdit(row) {
      this.pendingPrice = row.price
      row.isRemarkShow = false;
      this.operateType = "edit";
    },
    
    // 失焦时显示更改后价格
    blurFee(row){
      row.isRemarkShow = true;
      row.price = this.pendingPrice
      let obj = {
        groupId:row.groupId,
        price: this.pendingPrice
      }
      if(this.objList.length===0){
        this.objList.push(obj)
      }else{
        let find = false
        this.objList.forEach(item=>{
          if(item.groupId === row.groupId){
            item.price = this.pendingPrice
            find = true
          }
        })
      if(!find){
          this.objList.push(obj)
        }
      }
      this.groupIds.push(row.groupId)
      this.priceList.push(this.pendingPrice)
    },
  
    // 判断日期是否在一个月内
    ifOneMonth(){
      let diff = Math.abs(moment(this.value1[0]).diff(moment(this.value1[1]),'days'))
      if(Number(diff) > 30){
        this.$message.error(this.$t('Day30'))
        this.value1 = []
      }
    },
    
    // 确定
    submitData() {
      if(!this.value1|| this.value1.length === 0){
        this.$message.error(this.$t('EmptyDate'))
      }else {
        this.$confirm(
            this.$t('SixMonths') + this.$t('CurrentPeriod') + this.value1[0] + '~' + this.value1[1] + this.$t('ConfirmToConfirm'),
            this.$t('Attention'), {
              confirmButtonText: this.$t('Confirm'),
              cancelButtonText: this.$t('Reset'),
              dangerouslyUseHTMLString: true,
              type: 'warning'
            }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('SetSuccessful')
  
          });
  
          // 调接口
          this.loading = true
          let date = moment(this.value1[1]).add(1, 'day').format('YYYY-MM-DD')
          this.objList.forEach(item => {
            this.groupIds.push(item.groupId)
            this.priceList.push(item.price)
          })
          let param = {
            currentAdmin: this.adminId,
            startTime: this.value1[0] + ' 00:00:00',
            endTime: date + ' 00:00:00'
          }
          this.groupIds.length === 0 ? delete param.groupIds : param.groupIds = this.groupIds
          this.priceList.length === 0 ? delete param.priceList : param.priceList = this.priceList
          api.editGroup(param).then(res => {
            if (res.code === 200) {
              this.loading = false
              this.dialogVisible = false
              this.$emit('freshPage')
            }
          })
          // 调完了
  
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-group {
  // 表格区域
  .table-box {
    max-height: 450px;
    overflow-y: auto;
    table {
      width: 100%;
    }
    table,
    td {
      border: 1px solid #ebebeb;
      font-size: 12px;
      border-collapse: collapse;
    }
    tr {
      height: 35px;
    }
    td {
      padding: 0 10px;
    }
    .group-td {
      width: 250px;
    }
    .number-td {
      width: 150px;
    }
    .td-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .group-name,
      & > .group-node {
        flex: 1;
        width: 0;
        margin-right: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > u {
        cursor: pointer;
      }
      ::v-deep .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .oper-td {
      .el-button {
        padding: 0;
        border: none;
        background-color: #ffffff;
        color: #c50707;
        font-size: 12px;
      }
      .move-up {
        color: #8adf59;
      }
      .move-down {
        color: #bb6202;
      }
    }
  }
  
  .add-group {
    background-color: #589ef8;
    margin-top: 10px;
    color: #ffffff;
  }
}
.table-group:hover{
  background-color: #F5F7FA;
}
.payTime{
  margin: 20px 0;
  span{
    margin-right: 30px;
  }
}
</style>
<style lang="scss">
.manage-group {
  .el-dialog__header,
  .el-dialog__footer {
    text-align: center;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    .el-button {
      margin: 0 10px;
    }
  }
}
.el-date-range-picker__header [class*=arrow-left] {
  float: left;
}
.el-date-range-picker__header [class*=arrow-right] {
  float: right;
}
.el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 32px;
  width: 9%;
  color: #303133;
}
</style>